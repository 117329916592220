#root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f9f9f9;
}

.globiance-widget-XF99AS {
  position: relative;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--bgColor) !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background-color: #c1c1c1 !important;
    transition: background-color 0.3s !important;

    &:hover {
      opacity: 0.8 !important;
    }
  }

  .header {
    border-radius: 6px 6px 0px 0px;
    position: sticky;
    top: 0px;
  }

  .tooltip-div {
    display: flex !important;

    p {
      margin-left: 5px !important;
    }
  }

  .wid-collapse {
    background-color: var(--glob--bg-color) !important;
    color: var(--glob--text-color) !important;
    border-radius: 10px !important;
    align-items: center !important;
    position: fixed !important;
    display: flex !important;
    padding: 4px !important;
    height: 50px !important;
    bottom: 2% !important;
    width: 282px !important;
    right: 2% !important;

    p {
      margin-bottom: 0px !important;
      margin-left: 5px !important;
      font-family: $futura-md-bt !important;
      font-size: 12px !important;
    }

    img {
      cursor: pointer !important;
      height: 14px !important;
      width: 14px !important;
    }

    .logo {
      display: flex !important;
      align-items: center !important;
      margin: 0px 10px 0px 4px !important;

      svg {
        width: 34.5 !important;
        height: 27 !important;

        path {
          fill: var(--glob--logo-fill-color) !important;
        }
      }
    }
  }
}

.globiance-widget-XF99AS {
  &.modal {
    overflow: hidden !important;
  }

  .spinner-border {
    color: var(--glob--primary--color) !important;
    height: 100px !important;
    width: 100px !important;
  }

  p {
    margin-bottom: 0px !important;
  }

  .initializePaybis-screen {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-section {
      width: 80%;
      display: flex;
      align-items: center;
      flex-direction: column;

      > h6 {
        color: var(--glob--text-color);
        margin: 5px !important;
        font-size: 16px !important;
        font-weight: 800;
      }

      .buy-crypto-signup {
        padding: 2px 20px;
        color: var(--glob--primary--color);
        background: transparent !important;
        font-family: "Futura Hv BT";
        border: 1px solid var(--glob--primary--color);
        box-shadow: 0 0 0 2px var(--glob--primary--color-50);
        border-radius: 10px;
      }

      .buy-crypto-button {
        display: flex;
        padding: 3px 20px;
        align-items: center;
        border-radius: 10px;
        flex-direction: column;
        color: var(--glob--text-color);
        border: 1px solid var(--glob--text-color);
        box-shadow: 0 0 0 1px var(--glob--card-border-color);
        cursor: pointer;

        &.no-cursor {
          cursor: initial;
        }

        > h6 {
          margin: 0px;
          font-weight: 700;
          font-family: $futura-bold;
          font-size: 16px;
        }

        p {
          font-family: $futura-md-bt;
          font-weight: 100;
          color: var(--glob--text-color-secondary);
        }

        @media screen and (max-width: 405px) {
          > h6 {
            font-size: 13px !important;
          }

          p {
            font-size: 13px !important;
          }
        }
      }

      .buy-crypto {
        display: flex;
        align-items: center;
        font-family: $futura-bold;

        h6,
        span {
          margin: 0px !important;
          font-size: 14px !important;
          font-weight: 700;
          color: var(--glob--text-color);
        }

        @media screen and (max-width: 405px) {
          h6 {
            font-size: 9px !important;
          }
        }
      }

      .logo-list {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: center !important;
        white-space: nowrap !important;
        font-family: $futura-bold;

        span {
          margin-left: 10px !important;
        }

        img {
          width: 20px !important;
          height: 20px !important;
          margin: 0px 8px !important;
        }
      }

      .visa-section {
        text-align: center !important;
        // padding: 0px 20px;
        line-height: 1.7;

        p {
          width: auto;
          font-size: 14px !important;
          font-weight: 400 !important;
          font-family: $futura-md-bt !important;
          color: var(--glob--text-color-secondary) !important;
          line-height: 15px !important;
        }

        span {
          display: flex !important;
          text-align: center !important;
          align-items: center !important;
          justify-content: center !important;
          margin: 1px;
        }

        svg {
          fill: var(--glob--text-color-secondary) !important;
          margin: 0px 5px !important;
        }
      }

      .open-glob-wallet {
        position: relative;
        border: 1px solid var(--glob--primary--color);
        box-shadow: 0 0 0 2px var(--glob--primary--color-50);
        width: 90%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
        margin-top: 25px;
        margin-bottom: 2px;
        cursor: pointer;

        &.mt-10 {
          margin-top: 10px !important;
        }

        .or-separator {
          position: absolute;
          font-size: 14px;
          font-weight: 800;
          top: -21px;
        }

        &.no-cursor {
          cursor: initial;
        }

        > span {
          color: var(--glob--primary--color);
          font-size: 16px;
          font-weight: 800;
          position: absolute;
          top: -24px;
          font-style: italic;
          left: 0px;
          font-family: $futura-bold;

          @media screen and (max-width: 400px) {
            font-size: 14px;
            top: -22px;
          }
        }

        > h5 {
          font-size: 24px;
          font-weight: 800;
          text-align: center;
          white-space: nowrap;
          margin: 0px !important;
          font-family: $primary-font-family;
          color: var(--glob--primary--color);
        }

        > p {
          color: var(--glob--text-color);
          font-size: 12px;
          font-weight: 800;
          text-align: center;
          font-family: "Futura Md BT";

          > span {
            color: var(--glob--primary--color);
          }
        }

        @media screen and (max-width: 405px) {
          h5 {
            font-size: 18px;
          }
        }
      }
    }

    .email-sec {
      width: 100% !important;
      display: flex !important;
      align-items: center !important;
      flex-direction: column !important;
      justify-content: center !important;

      input,
      button {
        padding-left: 10px !important;
        border-radius: 5px !important;
        border: 1px solid var(--glob--card-border-color) !important;
      }

      button {
        padding: 0px 20px;
        color: var(--glob--primary--color);
        background: transparent !important;
        font-family: $primary-font-family;
        border: 1px solid var(--glob--primary--color);
        box-shadow: 0 0 0 2px var(--glob--primary--color-50);
        border-radius: 10px;

        height: 30px !important;
        display: flex !important;
        font-size: 16px !important;
        margin-top: 5px !important;
        font-weight: 400 !important;
        padding-left: 35px !important;
        text-align: center !important;
        align-items: center !important;
        line-height: 23.44px !important;
        justify-content: center !important;

        &:disabled {
          opacity: 0.6 !important;
        }

        .spinner-border {
          margin-left: 15px !important;
          height: 20px !important;
          width: 20px !important;
          color: #ffffff !important;
        }
      }

      > .buy-crypto-button {
        height: 46.23;
      }

      input {
        width: 100% !important;
        height: 35px !important;
        outline: none !important;
        color: #000000 !important;
        min-width: 300px !important;
        text-align: center !important;
        background: var(--glob--card-bg-color) !important;
        color: var(--glob--text-color-secondary) !important;
      }
    }

    .footer-IPS {
      background-image: url("../../assets/images/checkout_option_bottom_crop.png");
      background-color: #000;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 100%;
      height: inherit;
      min-height: 220px;
      margin-top: 4px;
    }
  }

  // New UI CLASSES

  .cursor {
    cursor: pointer !important;
  }

  .modal-footer.notification-blue {
    background: var(--glob--notification--background--blue) !important;
  }

  .modal-footer.notification-red {
    background: var(--glob--notification--background--red) !important;
  }

  .modal-footer.notification-green {
    background: var(--glob--notification--background--green) !important;
  }

  .modal-footer.notification-yellow {
    background: var(--glob--notification--background--yellow) !important;
  }

  .notification-default {
    background: var(--glob--notification--background--default) !important;
  }

  .notification-blue {
    background: var(--glob--notification--background--blue) !important;
  }

  .notification-red {
    background: var(--glob--notification--background--red) !important;
  }

  .notification-green {
    background: var(--glob--notification--background--green) !important;
  }

  .notification-yellow {
    background: var(--glob--notification--background--yellow) !important;
  }

  .notification-default {
    background: var(--glob--notification--background--default) !important;
  }

  .col-notification-blue {
    border-left: 10px solid var(--glob--notification--background--blue) !important;
  }

  .col-notification-red {
    border-left: 10px solid var(--glob--notification--background--red) !important;
  }

  .col-notification-green {
    border-left: 10px solid var(--glob--notification--background--green) !important;
  }

  .col-notification-yellow {
    border-left: 10px solid var(--glob--notification--background--yellow) !important;
  }

  .col-notification-default {
    border-left: 10px solid var(--glob--notification--background--default) !important;
  }

  .title-cls {
    width: 100%;
    justify-content: space-between !important;
    align-items: center !important;
    display: flex !important;

    svg {
      margin-right: 10px !important;
      cursor: pointer !important;
      width: 20px !important;
      height: 20px !important;
    }
  }

  .notification-status-bar {
    border-bottom: 1px solid var(--glob--card-border-color) !important;
    border-top: 1px solid var(--glob--card-border-color) !important;
    position: relative !important;
    min-height: 25px !important;
    height: 25px !important;
    position: sticky !important;
    top: 47px;
    background-color: var(--glob--bg-color);
    z-index: 1;
    overflow: hidden;
    width: 100%;
    background-color: var(--glob--primary--color);

    .slider-text {
      position: absolute;
      white-space: nowrap;
      text-align: center;
      width: 100%;
      // animation: slide 10s linear infinite;
    }

    @keyframes slide {
      0% {
        transform: translateX(100%);
        /* Start from right */
      }

      100% {
        transform: translateX(-100%);
        /* Move to left */
      }
    }

    > div {
      justify-content: center !important;
      align-items: center !important;
      overflow: hidden !important;
      display: flex !important;
      height: 100% !important;
      width: 100% !important;
    }

    p {
      color: var(--glob--notification--text--color) !important;
      font-family: $futura-bold !important;
      text-transform: uppercase !important;
      font-size: 14px !important;
    }

    .last {
      position: absolute !important;
      right: 5px !important;
    }
  }

  .main-page {
    font-family: $primary-font-family !important;
    color: var(--glob--text-color) !important;
    padding: 0px 22px !important;
    min-height: 100% !important;
    display: flex !important;
    flex-direction: column !important;

    .logo-area {
      background-color: var(--glob--card-bg-color) !important;
      border: 1px var(--glob--logoarea--border) !important;
      box-shadow: 0px 4px 4px 0px #00000040 !important;
      margin: 5px 0px !important;
      border-radius: 9.8px !important;
      padding: 5px 18px !important;

      .img-area {
        height: 80px !important;
        width: 80px !important;
        border-radius: 9.8px !important;
        overflow: hidden !important;

        img {
          height: 100% !important;
          width: 100% !important;
        }
      }

      .label-cls {
        font-family: $primary-font-family !important;
        color: var(--glob--primary--color) !important;
        font-size: 22px !important;
        line-height: 26px !important;

        small {
          white-space: nowrap !important;
          font-size: 18px !important;
        }

        @media screen and (max-width: 373px) {
          font-size: 18px !important;
          small {
            font-size: 14px !important;
          }
        }
      }

      .title-area {
        width: 100% !important;
        display: flex;
        justify-content: space-between;

        .detail-txt {
          color: #0074ff !important;
          display: flex !important;
          justify-content: flex-end !important;
          text-decoration: underline !important;
          cursor: pointer !important;
          font-size: 12px;
          line-height: 17px;
        }
      }

      .price-cls {
        font-family: $futura-bold !important;
        font-size: 35px !important;
        line-height: 25px !important;
        letter-spacing: -1px !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media screen and (max-width: 403px) {
          font-size: 25px !important;
        }
      }
    }

    .payment-opt-sec {
      justify-content: space-between !important;
      font-family: $futura-md-bt !important;
      align-items: center !important;
      display: flex !important;
      margin: 6px 0px;

      h5 {
        font-size: 20.1px !important;
        margin-bottom: 0px !important;
      }

      h6 {
        font-size: 15px !important;
        margin-bottom: 0px !important;
        color: var(--glob--text-color-secondary) !important;

        span {
          color: var(--glob--primary--color) !important;
          font-weight: 400 !important;
        }
      }
    }

    .curr-avail {
      p {
        font-size: 15px !important;
        font-family: $futura-md-bt !important;
        color: var(--glob--text-color-secondary) !important;
      }
    }
  }

  .quotes-section {
    &.scroll-sec {
      margin-top: 5px !important;
      font-family: $primary-font-family !important;
      overflow-y: auto !important;
      padding-right: 7px !important;
      padding-bottom: 5px !important;

      &.main {
        max-height: 395px !important;

        @media screen and (max-height: 822px) {
          max-height: calc(100vh - 485px) !important;
        }
      }

      &.qr {
        max-height: 145px !important;
        margin-top: 0px !important;

        @media screen and (max-height: 822px) {
          max-height: calc(100vh - 485px) !important;
        }
      }

      &::-webkit-scrollbar {
        background-color: transparent !important;
        height: 10px !important;
        width: 10px !important;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--glob--scroll-bg-color) !important;
        border-radius: 10px !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--glob--scroll-bar-color) !important;
        transition: background-color 0.3s !important;
        border-radius: 10px !important;

        &:hover {
          opacity: 0.8 !important;
        }
      }

      .static-card {
        color: #fff;
        padding: 6px 10px;
        display: flex;
        overflow: hidden;
        align-items: center;
        margin-bottom: 5px;
        border-radius: 0.5rem;
        background-color: #ff007a;
        justify-content: space-between;
        cursor: pointer;

        @media screen and (max-width: 500px) {
          &--left {
            width: 77%;

            .logo {
              width: 60%;
            }

            .payment-methods {
              width: 40%;
            }
          }

          &--right {
            width: 23%;
          }
        }

        &--left {
          display: flex;
          align-items: center;

          p {
            margin-left: 10px;
            white-space: nowrap;
            @media screen and (max-width: 380px) {
              font-size: 12px;
            }
          }

          .logo {
            height: 47.9px;
          }

          .logo-crop {
            height: 47.9px;
            width: 47.9px;
          }

          svg {
            margin-left: 5px;
          }

          .payment-methods {
            display: flex;
            align-items: center;

            .method-logo {
              height: 20px !important;
              margin-left: 5px !important;
            }

            svg {
              fill: #ffffff !important;
            }
          }
        }

        &--right {
          h5 {
            color: #fff !important;
            white-space: nowrap !important;
            font-size: 22px !important;
            margin: 0 !important;
          }
        }
      }

      .quote-card, .visa-master-card {
        background-color: var(--glob--card-bg-color);
        border: 1.15px solid var(--glob--card-border-color);
        justify-content: space-between !important;
        border-radius: 0.5rem !important;
        margin-bottom: 5px !important;
        align-items: center !important;
        padding: 6px 10px;
        display: flex !important;

        &.blue {
          background-color: #4885EB !important;
          color: #FFF !important;
        }

        &.teal {
          background-color: #12b6b6 !important;
          color: #FFF !important;
        }

        &.h-70 {
          min-height: 62px;
        }

        &--right {
          display: flex !important;
          flex-direction: column !important;
          align-items: flex-end !important;

          .network {
            display: flex !important;
            flex-direction: row !important;

            justify-content: end !important;
            margin-top: 3px !important;

            img {
              width: 15px !important;
              height: 15px !important;

              margin-left: 2px !important;
              margin-right: 2px !important;
            }
          }

          .imgs {
            white-space: nowrap;
            > img {
              width: 25px !important;
              height: 25px !important;

              margin-left: 2px !important;
              margin-right: 2px !important;
            }
          }
        }

        .pay-by-card-logo-container {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          justify-items: start;
          align-items: center;

          > p {
            color: var(--glob--text-color);
            white-space: nowrap;
            font-size: 9px;
          }

          // >img {
          //   display: none;
          // }
        }

        .pay-by-card-logo-container img {
          max-height: 25px;
          max-width: 55px;
          object-fit: contain;
        }

        .pay-by-card-logo-text {
          font-size: 16px;
        }

        @media screen and (max-width: 494px) {
          .pay-by-card-logo-container img {
            max-width: 33px;
          }

          .pay-by-card-logo-text {
            font-size: 10px;
          }
        }

        &--left {
          align-items: center !important;
          display: flex !important;

          .p-text {
            br {
              display: none;
            }
            @media screen and (max-width: 479px) {
              span {
                display: flex;
              }
            }

            @media screen and (max-width: 352px) {
              font-size: 14px;
            }
          }
        }

        img {
          margin-right: 14px !important;
          height: 48px !important;
          width: 48px !important;
        }

        h5 {
          font-family: $primary-font-family !important;
          flex-direction: column !important;
          margin-bottom: 0px !important;
          font-size: 17.2px !important;
          display: flex !important;

          span {
            color: var(--glob--text-color-secondary) !important;
            font-family: $futura-md-bt !important;
            font-size: 11px !important;
          }
        }
      }

      .visa-master-card {
        .visa-master-card--right {
          .imgs {
            >img {
              &:first-child {
                width: 65px !important;
                height: 40px !important;

              }
              width: 55px !important;
              height: 25px !important;
  
              margin-left: 2px !important;
              margin-right: 2px !important;
            }
          }
        }
      }
    }
  }

  .pay-and-scan-sec {
    font-family: $futura-md-bt !important;
    margin-top: 5px !important;

    > h5 {
      font-size: 15px !important;
    }

    > div {
      justify-content: space-between !important;
      display: flex !important;
    }

    .card-sec {
      border: 1.15px solid var(--glob--primary--color) !important;
      background-color: var(--glob--card-bg-color) !important;
      border-radius: 10px !important;
      align-items: center !important;
      display: flex !important;
      padding: 10px !important;
      width: 220px !important;

      svg {
        fill: var(--glob--text-color-secondary) !important;
        border: 1px solid var(--glob--qr-border-color) !important;
        margin-right: 5px !important;
        height: 38px !important;
        width: 39px !important;
      }

      h6 {
        font-family: $futura-bold !important;
        margin-bottom: 0px !important;
        font-size: 12px !important;
        color: var(--glob--primary--color) !important;
      }

      small {
        font-size: 9px !important;
        color: var(--glob--primary--color) !important;
      }
    }

    .disabled {
      pointer-events: none !important;
      opacity: 0.5 !important;
    }

    .ml5 {
      margin-left: 14px !important;
    }
  }

  .main-page-bottom {
    flex-grow: 1;
    display: flex;
    padding-bottom: 5px;
    flex-direction: column;
    justify-content: flex-end;
  }

  .checkout-head {
    justify-content: space-between !important;
    align-items: center !important;
    display: flex !important;

    h4 {
      font-family: $futura-md-bt !important;
      font-size: 20px !important;
    }

    h4,
    p {
      margin-bottom: 0px !important;
    }

    p {
      color: var(--glob--text-color-secondary) !important;
      font-family: $futura-md-bt !important;
      text-align: end !important;
      font-size: 10px !important;
    }
  }

  .copy-checkout,
  .copy-payment {
    border: 1.15px solid var(--glob--card-border-color) !important;
    background: var(--glob--card-bg-color) !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-radius: 5px !important;
    display: flex !important;
    padding: 5px !important;
    width: 100% !important;

    svg {
      height: 17px !important;
      width: 16px !important;
    }

    h4 {
      white-space: nowrap !important;
      margin-bottom: 0px !important;
      overflow: hidden !important;
      color: #535353 !important;
      font-size: 12px !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }

  .copy-checkout-subtext {
    color: var(--glob--text-color-secondary) !important;
    text-align: right !important;
    font-family: $futura-md-bt !important;
    font-size: 12px !important;

    &.top {
      position: absolute !important;
      right: 0px !important;
      top: -19px !important;
      @media screen and (max-width: 470px) {
        font-size: 8px !important;
      }
      @media screen and (max-width: 367px) {
        font-size: 6px !important;
      }
    }
  }

  .copy-payment {
    h4 {
      text-align: center !important;
      width: 100% !important;
    }
  }

  .modal-footer {
    padding: 6px !important;
    justify-content: space-between !important;

    background: var(--glob--form-head-foot-bg);

    .stat {
      font-size: 12px !important;
      font-family: $futura-md-bt !important;
      color: var(--glob--notification--text--color) !important;
      margin-left: 8px !important;
    }

    .status {
      font-size: 14px !important;
      font-family: $futura-bold !important;
      color: var(--glob--notification--text--color) !important;
      text-transform: uppercase !important;
      font-weight: 500 !important;
      margin-right: 8px !important;
    }
  }

  .go-back-bar {
    display: flex !important;
    align-items: center !important;
    padding: 8px 22px 8px 22px !important;

    h6 {
      font-family: $futura-md-bt !important;
      font-size: 20px !important;
      white-space: nowrap !important;
    }

    p {
      font-family: $futura-md-bt !important;
      font-size: 14.3px !important;
      color: var(--glob--text-color) !important;
    }

    &.between {
      justify-content: space-between !important;
    }

    &.end {
      justify-content: end !important;
    }

    &.py-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .active-pay {
    border: 2.3px solid var(--glob--primary--color) !important;
    background: var(--glob--selected-card-bg-color) !important;
  }

  .paymentList-screen {
    font-family: $futura-md-bt !important;
    color: var(--glob--text-color) !important;
    padding: 0px 22px 22px 22px !important;

    .refresh-bar {
      width: 100% !important;
      display: flex !important;
      justify-content: end !important;

      p {
        color: var(--glob--light-text-color) !important;
        font-family: $futura-md-bt !important;
        font-size: 14px !important;
        display: flex !important;
        align-items: center !important;

        img {
          margin-right: 5px !important;
          width: 13px !important;
          height: 13px !important;
        }
      }
    }
  }

  .payment-detail-sec {
    height: 100% !important;
    width: 100% !important;
  }

  .tab-navigation-group {
    padding: 0px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin: 0px !important;
    width: 100% !important;
    height: 51.2px !important;
    background-color: var(--glob--card-bg-color) !important;
    border-radius: 6px !important;
    margin-bottom: 10px !important;
    color: var(--glob--text-color-secondary) !important;

    .react-tabs__tab--selected {
      background: var(--glob--selected-card-bg-color) !important;
      border: 1.15px solid var(--glob--primary--color) !important;
      border-radius: 3px !important;
    }

    .tab-navigation-group-item {
      cursor: pointer !important;
      text-transform: capitalize !important;
      font-family: "Roboto" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 15px !important;
      color: var(--tab-list--group-item-color) !important;
      padding: 10px 15px !important;
      height: 100% !important;
      align-items: center !important;
      justify-content: center !important;
      display: flex !important;

      &::marker {
        content: none !important;
      }

      &.wallet-chart-toggle {
        padding: 0px !important;
      }
    }
  }

  .detail-sec {
    border: 1.15px solid var(--glob--card-border-color) !important;
    color: var(--glob--text-color) !important;
    background: var(--glob--paymentdeatil-card-bg) !important;
    border-radius: 10px !important;
    padding: 9px !important;

    .row-li {
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      border-bottom: 1.15px solid var(--glob--card-border-color) !important;
      padding: 6px 0px 6px 0px !important;

      p {
        color: var(--glob--text-color) !important;
        font-family: $futura-md-bt !important;
        line-height: 17px !important;
        font-size: 14px !important;

        &:first-child {
          color: var(--glob--text-color-secondary) !important;
        }
      }

      .status {
        text-transform: uppercase !important;
        color: var(--glob--primary--color) !important;
        font-weight: 500 !important;
      }

      .address {
        display: flex !important;
        align-items: center !important;
        justify-content: end !important;

        p {
          width: 100px !important;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      }
    }

    .row-li:last-child {
      border-bottom: none !important;
    }
  }

  .circle-filled {
    fill: var(--glob--logo-fill-color) !important;
  }

  .circle-filled-st {
    stroke: var(--glob--logo-fill-color) !important;
  }

  .trans-det {
    h4 {
      margin-top: 30px !important;
      font-size: 20px !important;
      font-family: $futura-md-bt !important;
    }

    .bar {
      background-color: var(--glob--bg-color) !important;
      width: 100% !important;
      height: 35px !important;
      display: flex !important;
    }

    .bar-block {
      display: table-cell !important;
      height: 35px !important;
      border: 1px solid var(--glob--card-border-color) !important;

      p {
        margin-top: 8px !important;
        font-size: 10px !important;
        font-family: $primary-font-family !important;
        color: var(--glob--notification--text--color) !important;
        white-space: nowrap !important;
      }
    }

    .done {
      background-color: var(--glob--progressbar--color) !important;
    }

    .remaining {
      background-color: var(--glob--bar-rermaining-color) !important;
    }

    .bar-block:first-child {
      border-radius: 5px 0 0 5px !important;
    }

    .bar-block:last-child {
      border-radius: 0 5px 5px 0 !important;
    }
  }

  .payment-status-sec {
    border: 1.15px solid var(--glob--card-border-color) !important;
    color: var(--glob--text-color) !important;
    background: var(--glob--paymentdeatil-card-bg) !important;
    border-radius: 10px !important;
    padding: 15px !important;

    .StepProgress {
      position: relative !important;
      padding-left: 0px !important;
      list-style: none !important;

      &::before {
        display: inline-block !important;
        content: "" !important;
        position: absolute !important;
        top: 0 !important;
        right: 22px !important;
        width: 10px !important;
        height: 100% !important;
        border-left: 2px solid #ccc !important;
      }

      &-item {
        position: relative !important;
        counter-increment: list !important;

        &:not(:last-child) {
          padding-bottom: 20px !important;
        }

        &::before {
          display: inline-block !important;
          content: "" !important;
          position: absolute !important;
          right: 22px !important;
          height: 100% !important;
          width: 10px !important;
        }

        &::after {
          content: "" !important;
          display: inline-block !important;
          position: absolute !important;
          top: 0 !important;
          right: 15px !important;
          width: 31px !important;
          height: 31px !important;
          border: 2px solid #ccc !important;
          border-radius: 50% !important;
          background-color: #fff !important;
        }

        &.is-done {
          &::before {
            border-left: 2px solid var(--glob--progressbar--color) !important;
          }

          &::after {
            content: "✔" !important;
            font-size: 18px !important;
            color: #fff !important;
            text-align: center !important;
            border: 2px solid var(--glob--progressbar--color) !important;
            background-color: var(--glob--progressbar--color) !important;
          }
        }

        &.current {
          &::before {
            border-left: 2px solid var(--glob--progressbar--color) !important;
          }

          &::after {
            padding-top: 1px !important;
            width: 31px !important;
            height: 31px !important;
            top: -4px !important;
            right: 15px !important;
            font-size: 14px !important;
            text-align: center !important;
            color: var(--glob--progressbar--color) !important;
            border: 2px solid var(--glob--progressbar--color) !important;
            background-color: var(--glob--progressbar--color) !important;
          }
        }
      }

      h6 {
        font-size: 14px !important;
        font-family: $futura-md-bt !important;
        margin-bottom: 0px !important;
      }

      p {
        font-size: 11px !important;
        font-family: $futura-md-bt !important;
      }
    }
  }

  .blockchain-screen {
    padding: 0px 22px 22px 22px !important;
  }

  .blockchain-screen {
    .select-network {
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;

      padding-top: 10px !important;
      border-top: 1px solid var(--glob--card-border-color) !important;

      padding-bottom: 10px !important;
      border-bottom: 1px solid var(--glob--card-border-color) !important;

      &-left,
      &-right {
        color: var(--glob--text-color) !important;
      }

      select {
        &:hover {
          border-color: var(--glob--logo-fill-color) !important;
        }

        color: var(--glob--text-color) !important;
        background-color: var(--glob--bg-color) !important;
        padding: 5px !important;

        border-radius: 5px !important;

        border-color: var(--glob--card-border-color) option {
          background-color: var(--glob--bg-color) !important;
        }

        &:focus {
          outline: none !important;
        }
      }
    }

    .network-label {
      font-family: "Futura Md BT" !important;
      color: var(--glob--text-color) !important;
      font-size: 15px;
    }

    .copy-payment {
      h4 {
        color: var(--glob--text-color) !important;
      }
    }


    .instax-wrapper {
      height: 660px;
      width: 100%;
      margin-top: 2px;
      border-radius: 5px;
  
      .instax-wrapper-iframe {
        height: 100%;
        width: 100%;
        border-radius: 5px;
      }
    }
  }

  .qr-sec {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative;
    font-size: 12px;
    > span {
      font-size: 14px;
      color: var(--glob--primary--color);
      font-family: "futura-bold";
      text-align: center;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
      @media screen and (min-width: 450px) {
        position: absolute;
        right: 20px;
        top: 25%;
        width: 100px;
        text-align: start;
        font-size: 14px;
      }
    }

    svg {
      border: 1px solid var(--glob--qr-border-color) !important;
      width: 218.3px !important;
      height: 218.3px !important;
      fill: var(--glob--text-color) !important;
    }

    &.wallet {
      svg {
        border: 1px solid var(--glob--qr-border-color) !important;
        width: 200px !important;
        height: 200px !important;
        fill: var(--glob--primary--color) !important;
      }
    }
  }

  .pay-section {
    padding: 10px !important;
    text-align: center !important;
    background: var(--glob--pay-section-bg-color) !important;
    border: 1.15px solid var(--glob--card-border-color) !important;
    border-radius: 10px !important;
    margin: 19px 0px 19px 0px !important;

    h3 {
      color: var(--glob--notification--text--color) !important;
      text-transform: uppercase !important;
      margin-bottom: 0px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      font-size: 28px !important;
      font-family: $primary-font-family !important;
    }

    img {
      width: 40px !important;
      height: 40px !important;
      margin-left: 10px !important;
    }
  }

  .breakdown-section {
    .breakdown-sec {
      margin: 12px 0px 12px 0px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      font-family: $futura-md-bt !important;
      color: var(--glob--text-color) !important;

      h5 {
        font-size: 20.1px !important;
      }

      h6 {
        font-size: 16px !important;
        color: var(--glob--text-color-secondary) !important;

        span {
          font-weight: 500 !important;
          color: var(--glob--primary--color) !important;
          font-family: $futura-bold !important;
        }
      }
    }
  }

  .qr-screen {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    height: 100%;

    .footer-IPS {
      background-image: url("../../assets/images/checkout_option_bottom_crop.png");
      background-color: #000;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 100%;
      height: inherit;
      min-height: 220px;
      margin-top: 4px;
      flex-grow: 1;
    }

    p {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      font-family: $primary-font-family !important;
      font-size: 14px !important;

      span {
        color: var(--glob--primary--color);
      }
    }

    .qr-sec {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
    }

    .text {
      margin-top: 5px !important;

      p {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-family: $primary-font-family !important;
        font-size: 14px !important;

        .or,
        .scan-text {
          color: var(--glob--text-color-secondary) !important;
        }

        .info-text {
          font-size: 12px !important;
          text-align: center !important;
          color: var(--glob--text-color-secondary) !important;
        }

        .login {
          color: var(--glob--primary--color) !important;
        }

        .or {
          font-family: $futura-md-bt !important;
        }
      }
    }

    .avail-curr {
      margin: 0px 22px;
      h5 {
        font-size: 14px !important;
        margin-bottom: 0px;
        font-family: $primary-font-family !important;
        color: var(--glob--text-color) !important;
      }
    }
  }

  .download-sec {
    margin-top: 5px !important;
    display: flex !important;
    justify-content: space-between !important;

    button {
      border-radius: 5px !important;
      background: var(--glob--card-bg-color) !important;
      color: var(--glob--primary--color) !important;
      border: 1px solid var(--glob--primary--color) !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      svg {
        width: 20px !important;
        height: 20px !important;
        margin-left: 5px !important;
        fill: var(--glob--primary--color) !important;
      }
    }

    p {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      color: var(--glob--text-color-secondary) !important;
      font-family: $primary-font-family !important;
      font-size: 14px !important;

      a {
        margin: 0px 5px !important;
      }

      svg {
        width: 21px !important;
        height: 25px !important;
        fill: var(--glob--text-color) !important;
        margin: 0px 4px !important;
      }
    }
  }

  .cancel-screen {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: var(--glob--text-color) !important;

    > div {
      text-align: center !important;
    }

    svg {
      width: 278px !important;
      height: 278px !important;
    }

    h6 {
      font-size: 18px !important;
      font-family: $primary-font-family !important;
      margin-bottom: 27px 0px !important;
    }

    .btn-grp {
      width: 100% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      font-family: $primary-font-family !important;

      .yes {
        height: 59px !important;
        width: 148px !important;
        border-radius: 10px !important;
        border: 1px solid var(--glob--card-border-color) !important;
        background: var(--glob--card-bg-color) !important;
        color: var(--glob--text-color-secondary) !important;
        display: flex;
        align-items: center;
        justify-content: center;

        &.disabled {
          opacity: 0.5;
        }

        .spinner-border {
          width: 22px !important;
          height: 22px !important;
          margin-left: 5px;
        }
      }

      .no {
        height: 59px !important;
        width: 148px !important;
        margin-left: 27px !important;
        border-radius: 10px !important;
        color: #FFF !important;
        border: 1px solid var(--glob--card-border-color) !important;
        background: var(--glob--primary--color) !important;
      }
    }
  }

  .completed-sec {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    color: var(--glob--text-color) !important;

    > div {
      text-align: center !important;
    }

    img {
      width: 278px !important;
      height: 278px !important;

      margin-bottom: 20px !important;
    }

    h6 {
      font-size: 18px !important;
      font-family: $primary-font-family !important;
      margin-bottom: 27px 0px !important;
    }
  }

  .expiry-screen {
    padding: 0px 22px 22px 22px !important;

    svg {
      width: 60% !important;
      fill: var(--glob--text-color) !important;
      border: 1px solid var(--glob--qr-border-color) !important;
    }

    text-align: center !important;

    .svg {
      width: 25px !important;
      height: 25px !important;
      fill: var(--glob--text-color) !important;
      border: 1px solid var(--glob--qr-border-color) !important;
    }

    h1,
    h4 {
      color: var(--glob--text-color) !important;
    }
  }

  .expiry-nav-bar {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 5px !important;

    h5 {
      display: flex !important;
      align-items: center !important;

      h5 {
        margin-bottom: 0px !important;
        color: $primary !important;
      }
    }
  }

  .blink_me {
    animation: blinker 1s linear infinite !important;
  }

  @keyframes blinker {
    50% {
      opacity: 0.5 !important;
    }
  }

  .error-screen {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    margin: 0px 20px !important;

    h3,
    h6 {
      color: var(--glob--text-color) !important;
    }

    .err-img-container {
      text-align: center !important;

      img {
        width: 150px !important;
        height: 150px !important;
        margin: 10px !important;
      }
    }

    .svg {
      width: 25px !important;
      cursor: pointer !important;
      fill: var(--glob--text-color) !important;
      border: 1px solid var(--glob--qr-border-color) !important;
    }
  }

  .app-scroll {
    flex-grow: 1;

    &::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
      background-color: transparent !important;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--bgColor) !important;
      border-radius: 10px !important;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
      background-color: #c1c1c1 !important;
      transition: background-color 0.3s !important;

      &:hover {
        opacity: 0.8 !important;
      }
    }
  }

  .cls-1 {
    fill: $primary;
  }

  .cls-2 {
    fill: #0074ff !important;
  }

  .item-details {
    font-family: "Roboto" !important;
    font-weight: 400 !important;
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    color: var(--glob--text-color) !important;

    .back-button-bar {
      display: flex !important;
      justify-content: flex-end !important;
      padding: 10px 0px !important;

      > p {
        font-size: 16px !important;
        cursor: pointer !important;
      }
    }

    h5 {
      font-size: 16px !important;
      margin-top: 10px !important;
    }

    table {
      border-spacing: 0px 7px !important;
      border-collapse: separate !important;
      color: var(--glob--text-color) !important;

      tr {
        border: 1px solid var(--glob--card-border-color) !important;

        td {
          font-size: 16px !important;
          border-top: 1px solid var(--glob--card-border-color) !important;
          border-bottom: 1px solid var(--glob--card-border-color) !important;

          &:first-child {
            border-left: 1px solid var(--glob--card-border-color) !important;
          }

          &:last-child {
            border-right: 1px solid var(--glob--card-border-color) !important;
          }
        }

        th {
          font-weight: 400 !important;
          border-top: 1px solid var(--glob--card-border-color) !important;
          border-bottom: 1px solid var(--glob--card-border-color) !important;

          &:first-child {
            border-left: 1px solid var(--glob--card-border-color) !important;
          }

          &:last-child {
            border-right: 1px solid var(--glob--card-border-color) !important;
          }
        }
      }
    }

    .total-section {
      > div {
        margin: 3px 0px !important;
        padding: 0px 7px !important;
        display: flex !important;
        border: 1px solid var(--glob--card-border-color) !important;
        justify-content: space-between !important;

        > p {
          font-size: 20px !important;
        }
      }
    }

    .tab-total {
      display: flex !important;
      flex-direction: column !important;
      flex: 1 !important;
      justify-content: space-between !important;
    }
  }


  .master-visa-payment-form {
    max-width: 430px;
    margin: 30px auto 0px auto;
    // border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 20px;
    // background-color: #FFFFFF;
    color: var(--glob--text-color) !important;
    background-color: var(--glob--master-visa-form-bg-color) !important;
    border: 1px solid var(--glob--master-visa-form-border-color);
    h2 {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 20px;
    }
  
    .form-group {
      margin-bottom: 15px;
  
      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 0.9rem;
      }
  
      input:not([type="checkbox"]) {
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        font-size: 1rem;
        font-family: Roboto !important;
        color: var(--glob--text-color) !important;
        background-color: var(--glob--master-visa-form-input-bg-color) !important;
        border: 1px solid var(--glob--card-border-color) !important;
        &:focus {
          outline: none;
        }
      }

      .position-relative {
        .icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translatey(-50%);
          color: #a39c9c;
          cursor: pointer;
        }
      }
    }
  
    .remember-card {
      display: flex;
      align-items: center;
      input {
        margin-right: 6px;
      }
      label {
        font-size: 0.9rem;
      }
    }
  
    .submit-button {
      width: 120px;
      min-width: 120px;
      padding: 6px;
      border: none;
      border-radius: 6px;
      background-color: var(--glob--primary--color);
      color: #fff;
      font-size: 1rem;
      cursor: pointer;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
    
  }
}

.not-found {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
}


.currency-select-container {
  width: 100%;
  padding: 0px 20px;
  font-family: "Futura Md BT" !important;

  .label {
    font-weight: 400;
    margin-bottom: 8px;
    font-size: 20px;
    display: block;
    color: var(--glob--text-color) !important;
  }

  .search-input-container {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    background: var(--glob--card-bg-color) !important;
    color: var(--glob--text-color) !important;
  }

  .search-input {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    padding-right: 10px;
    color: var(--glob--text-color) !important;
  }

  .currency-icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }

  .dropdown {
    position: absolute;
    top: 101%;
    left: 0;
    right: 0;
    background: var(--glob--bg-color) !important;
    color: var(--glob--text-color) !important;
    border: 1px solid var(--glob--card-border-color);
    border-radius: 8px;
    margin-top: 1px;
    z-index: 10;
    max-height: 460px;
    overflow-y: auto;
    margin-top: 5px;

    &::-webkit-scrollbar {
      background-color: transparent !important;
      height: 10px !important;
      width: 10px !important;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--glob--scroll-bg-color) !important;
      border-radius: 10px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--glob--scroll-bar-color) !important;
      transition: background-color 0.3s !important;
      border-radius: 10px !important;

      &:hover {
        opacity: 0.8 !important;
      }
    }
  }

  .loadertext-color{
    color: var(--glob--text-color);
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;

    >img {
      margin-right: 15px;
    }
  }

  .dropdown-item:hover {
    background: var(--glob--card-bg-color) !important;
    color: var(--glob--text-color-secondary) !important;
  }

  .no-results {
    padding: 10px;
    text-align: center;
    color: var(--glob--text-color-secondary) !important;
  }
}