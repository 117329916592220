.MainContainer {
  width: 100vw;
  max-height: calc(99vh - 97px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: var(--glob--bg-color-invoice);
  margin-top: 95px;

  @media screen and (max-width: 541px) {
    margin-top: 107px;
  }
}

.labelCls {
  h5 {
    color: var(--glob--text-color);
  }
}

.Taxsection {
  width: 100%;
  height: 87px;
  padding: 10px;
  max-width: 530px;
  border-radius: 6px;
  margin-top: 19px;
  background-color: var(--glob--card-bg-color);
  border: 1px solid var(--glob--card-border-color);
}

.CheckoutSec {
  width: 100%;
  height: 700px;
  max-width: 530px;
  margin-top: 19px;
  border-radius: 6px;
  background-color: var(--glob--bg-color);
  border: 1px solid var(--glob--card-border-color);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--bgColor) !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background-color: #c1c1c1 !important;
    transition: background-color 0.3s !important;

    &:hover {
      opacity: 0.8 !important;
    }
  }
}

.img-area {
  height: 65px;
  width: 80px;
  border-radius: 9.8px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }
}

.copy-checkout {
  border: 1.15px solid var(--glob--card-border-color);
  background: var(--glob--card-bg-color);
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  padding: 5px;
  width: 100%;

  svg {
    height: 17px;
    width: 16px;
  }

  h4 {
    white-space: nowrap;
    margin-bottom: 0px;
    overflow: hidden;
    color: #535353;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.copy-checkout-subtext {
  color: var(--glob--text-color-secondary);
  text-align: right;
  font-family: "Futura Md BT";
  font-size: 12px;
}
