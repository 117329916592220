.HeaderLogoIcon {
  height: 45px !important;
  margin-left: 21px;
  margin-right: 2px;
}

.BottomLine {
  top: 0px;
  width: 100%;
  padding: 5px;
  z-index: 999;
  height: 66px;
  display: flex;
  position: fixed;
  overflow: hidden;
  background: var(--glob--bg-color);
  align-items: center;
  border-bottom: 1px solid var(--glob--card-border-color);

  > h1 {
    color: var(--glob--text-color);
    font-family: FuturaDemiC;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
  }
}
