@import "bootstrap/scss/bootstrap.scss";

@import "abstracts/functions";
@import "abstracts/mixins";

@import "variables/variables";

@import "base/fonts";

@import "base/animations";
@import "base/base";
@import "base/typographic";
@import "base/utilities";

@import "components/rb-modal.scss";
@import "components/widget.scss";
@import "components/header.scss";
@import "components/ErrorFallBack.scss";

#globiance-pay {
  .globiance-button {
    height: 59px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #fe036a;
    background: #fff;
    color: #fe036a;
    font-family: "Futura Hv BT";
    background-image: url("../assets/images/header/logo.png");
    background-size: 40px 40px;
    background-position: 4% center;
    background-repeat: no-repeat;
    padding-left: 20%;
  }
}

#root,
body {
  background-color: var(--glob--bg-color-invoice);
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
