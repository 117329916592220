.globiance-widget-XF99AS {
  .ErrorFallBack {
    background-repeat: no-repeat;
    justify-content: bottom;
    align-items: center;
    min-height: 100vh;
    display: flex;
    button {
      background-color: #fe036a;
      border-radius: 8px;
      font-size: 18px;
    }
  }

  .btn-primary {
    background: blue !important;
  }
  .fw-semibold {
    font-weight: 600;
    color: #495057;
  }
  .text-primary {
    --bs-text-opacity: 1;
    color: blue !important;
  }

  .new-logo {
    position: absolute;
    top: 3%;
    left: 3%;
  }
}
