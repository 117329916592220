.globiance-widget-XF99AS {
  .modal-content {
    border-radius: 1rem;
    overflow: hidden;
  }

  .modal-header {
    color: white;
    background-color: #060526;
  }

  .redius-rounded-top {
    border-radius: 0.9rem 0.9rem 0 0;
  }

  .redius-rounded-bottom {
    border-radius: 0 0 0.9rem 0.9rem;
  }
}
