.MainSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 27px;
  flex: 1;
}

.Bottom {
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    text-align: start;

    img {
      width: 163px;
      height: 50px;

      &:first-child {
        margin-right: 17px;
      }
    }

    @media only screen and (max-width: 480px) {
      img:last-child {
        margin-top: 10px;
      }
    }

    p {
      font-size: 26px;
      color: #aaacb9;
      margin-top: 4px;
      font-family: "Roboto";
      font-weight: 400;
    }
  }
}

.rightBanner {
  background-image: url("../../assets/images/Asset\ 600x600\ v2.png");
  background-position: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 600px;
  width: 600px;
}

.MidSection {
  color: var(--glob-text-color-dark);
  display: grid;
  height: 100%;
  flex: 1;

  @media only screen and (max-width: 767px) {
    div:first-child {
      order: 1;
      margin-bottom: 20px;
    }

    div:last-child {
      order: 0;
    }
  }

  .headingSection {
    grid-row: 2/3;
  }

  .sideSection {
    grid-row: 1/3;
  }

  span {
    color: #fe036a;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1 {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 96px;
    max-width: 100%;
  }

  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 60px;
    }
  }

  h5 {
    width: 80%;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 36px;
  }

  button {
    width: 345px;
    height: 64px;
    display: flex;
    max-width: 100%;
    font-size: 26px;
    color: #ffffff;
    border-radius: 6px;
    align-items: center;
    border: 1px solid #fe036a;
    background-color: #fe036a;
    margin-top: 40px;

    > p {
      width: 100%;
      text-align: center;
      margin-bottom: 0px;
      font-weight: 400;
      font-family: "Roboto";
    }

    > img {
      width: 20px;
      height: 20px;
    }
  }
}
