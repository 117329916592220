.globiance-widget-XF99AS {
  .modal-backdrop.show {
    opacity: 1;
    backdrop-filter: blur(2px);
    background-color: var(--app-modal__backdrop-bg-color);
  }

  .modal-dialog {
    margin: auto;
    display: flex;
    align-items: center;
    height: -webkit-fill-available;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      width: 80%;
    }
  }

  .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23535353%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
      center/1em auto no-repeat;
  }

  .modal-content {
    border: none;
    background-color: var(--glob--bg-color);
    height: 850px;

    .modal-header {
      border: none;
      align-items: center;

      .modal-title {
        font-size: 20px;
        font-weight: 500;
        width: 100%;
        text-align: center;
        line-height: 36px;
        color: var(--app-modal__title-color);
        font-family: "FuturaDemiC";
        text-transform: capitalize;
      }
    }

    .modal-body {
      padding: 0px !important;
    }

    @media only screen and (max-height: 855px) {
      height: 97vh;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 500px) {
  .modal-content {
    width: 80vw !important;
  }
}

@media screen and (max-width: 768px) {
  .modal-content {
    max-width: none !important;
    width: 80vw !important;
    display: flex !important;
    margin: auto !important;
  }

  .modal-dialog {
    max-width: none !important;
  }
}

@media screen and (max-width: 500px) {
  .modal-content {
    width: 90vw !important;
  }
}
