:root[data-theme="light"] {
  --header__container--border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  --header__container--bg-box-shadow: none;
  &:root[data-color="black"] {
    --header__link--bg-color: rgba(0, 0, 0, 0.31);
  }
}

:root[data-theme="dark"] {
  --header__container--border-bottom: none;
  --header__container--bg-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  &:root[data-color="black"] {
    --header__globiance--logo: url("../../assets/images/header/Globiance_Pay_gold.png");
  }
}

:root[data-color="pink"] {
  --header__globiance--logo: url("../../assets/images/header/Globiance_Pay_pink.png");
}

:root[data-color="black"] {
  --header__globiance--logo: url("../../assets/images/header/Globiance_Pay_gold.png");
}

.globiance-widget-XF99AS {
  .header {
    background: var(--glob--bg-color);
    box-shadow: var(--header__container--bg-box-shadow);
    border-bottom: var(--header__container--border-bottom);
    height: 47px;
    display: flex;
    z-index: 1;

    .headerLogo {
      background-image: var(--header__globiance--logo);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 280px 35px;
      width: 100%;
      min-height: 46px;
    }
  }
}
