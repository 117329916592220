:root {
  --appBorderRadius: 6px;
  --glob--scroll-bg-color: #d9d9d9;
  --glob--scroll-bar-color: #a4a5a5;
  --glob--logoarea--border: #000000;
  --glob--light-text-color: #666666;
  --glob--bar-rermaining-color: #d9d9d9;
  --glob--pay-section-bg-color: #adbeff;
  --glob--pay-section-border-color: #738cee;
  --glob--notification--text--color: #ffffff;
  --glob--notification--background--red: #ff5a5a;
  --glob--notification--background--blue: #5593f0;
  --glob--notification--background--green: #34d658;
  --glob--notification--background--yellow: #f5c534;
  --glob--notification--background--default: #fe036a;
}

:root[data-theme="light"] {
  --glob--bg-color-invoice: #f9f9f9;
  --glob--bg-color: #f9f9f9;
  --glob--text-color: #535353;
  --glob--text-color-secondary: #535353;
  --glob--card-bg-color: #e2e4e6;
  --glob--paymentdeatil-card-bg: #f5f5f5;
  --glob--card-border-color: #c9c9c9;
  --glob--primary--color-50: #fe036a50;
  --glob--logo-fill-color: #fe036a;
  --theme-toggle-circle: #ffffff;
  --theme-toggle-bg: #d9d9d9;
  --glob-text-color-dark: #000000;
  &:root[data-color="black"] {
  }
}
:root[data-theme="dark"] {
  --glob--bg-color-invoice: #25262a;
  --glob--bg-color: #0d0d0d;
  --glob--text-color: rgba(255, 255, 255, 0.9);
  --glob--text-color-secondary: #ffffff80;
  --glob--card-bg-color: #181a20;
  --glob--paymentdeatil-card-bg: #181a20;
  --glob--qr-border-color: rgba(255, 255, 255, 0.9);
  --glob--card-border-color: #d7d7d712;
  --glob--logo-fill-color: #ffd455d9;
  --theme-toggle-circle: #343a40;
  --theme-toggle-bg: #181a20;
  --glob-text-color-dark: #ffffff;

  &:root[data-color="pink"] {
  }
  &:root[data-color="mint"] {
  }
  &:root[data-color="black"] {
    --glob--primary--color: #ffd455d9;
    --glob--selected-card-bg-color: rgba(255, 212, 85, 0.25);
    --glob--progressbar--color: rgba(255, 212, 85, 0.85);
  }
}
:root[data-color="pink"] {
  --glob--primary--color: #fe036a;
  --glob--selected-card-bg-color: #ffc0d7;
  --glob--progressbar--color: #ff65a7;
}
:root[data-color="mint"] {
}
:root[data-color="black"] {
}
