@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://use.fontawesome.com/releases/v5.7.0/css/all.css");

@font-face {
  font-family: FuturaDemiC;
  src: url("https://cdn.globiance.com/fonts/FuturaDemiCBold.ttf")
    format("truetype");
}

@font-face {
  font-family: Futura Hv BT;
  src: url("https://cdn.globiance.com/fonts/FuturaHeavyBT.ttf")
    format("truetype");
}

@font-face {
  font-family: futura-bold;
  src: url("https://cdn.globiance.com/fonts/unicode.futurab.ttf")
    format("truetype");
}

@font-face {
  font-family: Futura Md BT;
  src: url("https://cdn.globiance.com/fonts/FuturaMediumBT.ttf")
    format("truetype");
}
